.adminWP{
    background: #f1f1f1;
    height: 100vh;
    display: grid;
    place-items: center;
}

.adminWrapper {
    border: 1px solid #7a457d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.5px;
    /* padding: 0 20px; */
    /* margin: 100px auto 0 auto; */
    width: 320px;

}

.test {
    margin: 10px !important;
}

.btnn {
    background-color: #7a457d;
    color: #bdb4bf;
    margin: 10px;
    border: 0;
    height: 50px;
    border-radius: 5px;
}

.btnn:hover{
    cursor: pointer;
    background-color: #a96dad;
}

.formAdmin {
    display: flex;
    flex-direction: column;
    margin: 25px 0;
    width: 66%;
    /* border: 1px solid sienna */
}