.site-main{
    padding: 0 4vw 4vw 4vw;
}

.inner{
    margin: 0 auto;
    max-width: 1040px;
    width: 100%;
    font-family: 'EB Garamond', serif !important;
}

.postFullHeader{
    margin: 0 auto;
    padding: 1.5vw;
    max-width: 1040px;
    text-align: center;
}

.postDateFull{
    display: flex;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #7a457d;
}

.postFullTitle{
    margin: 0;
    color: #090a0b;
    font-size: 45px;
}

.postFullImage {
    margin: 0 -6vw -165px;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.postFullImage img {
    vertical-align: middle;
    border-radius: 5px;
}

@media screen and (max-width: 1170px) {
    .postFullImage {
        margin: 0 -4vw -100px;
        border-radius: 0;
    }

    .postFullImage img {
        height: 600px;
        vertical-align: middle;
    }

    .postFullContent {
        padding: 5vw 7vw 0;
    }
}

@media screen and (max-width: 855px) {
    .postFullImage img {
        height: auto !important;
        vertical-align: middle;
    }

    .postFullImage {
        margin: 0 -4vw -10% !important;
    }

    .postFullContent {
        padding: 50px 10% 0 !important;
    
    }
}

.postFullImage img {
    max-width: 100%;
    height: 800px;
    object-fit: cover;
}

.postFullContent {
    position: relative;
    margin: 0 auto;
    padding: 70px 100px 0;
    min-height: 230px;
    font-family: Georgia,serif;
    font-size: 2.2rem;
    line-height: 1.6em;
    background: #fff;
}

.postFullContent:before {
    left: -5px;
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
    content: "";
    position: absolute;
    top: 15px;
    z-index: -1;
    display: block;
    width: 20px;
    height: 200px;
    background: rgba(39,44,49,.15);
    -webkit-filter: blur(5px);
    filter: blur(5px);

}

.postFullContent:after {
    content: "";
    position: absolute;
    top: 15px;
    z-index: -1;
    display: block;
    width: 20px;
    height: 200px;
    background: rgba(39,44,49,.15);
    -webkit-filter: blur(5px);
    filter: blur(5px);
    right: -5px;
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
}

.postContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 920px;
}

.postContent img {
    max-height: 400px;
    max-width: 100%;
    margin: 0 auto;
}

.postContent p {
    margin: 0 0 1.5em;
    font-size: 0.64em;
    line-height: 1.2em;
}

.postContent > p:first-child{
    font-size: 0.8em;
    line-height: 1.2em;
}