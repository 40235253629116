.blogPostsWrap {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.blogPostsMainWrap {
    border: 1px solid #7a457d;
    display: flex;
}

.blogEditor{
    border: 1px solid #7a457d !important;
    min-width: 360px;
    padding: 10px 0;
    text-align: center;
    flex: 0.6

}

.slateEditor{
    max-width: 42em;
    background: rgb(255, 255, 255);
    margin: 20px auto;
    padding: 20px;
}


.slateEditor > div:nth-child(2n) {
    margin-left: -10px;
    margin-right: -16px;
    text-align: left;
    height: 216px;
    overflow: auto;
}
.slateEditor html, .slateEditor input, .slateEditor textarea {
    font-family: 'Roboto', sans-serif;
    line-height: 1.4;
    background: #eee;
}
.slateEditor body {
    margin: 0;
}
.slateEditor p {
    margin: 0;
}
.slateEditor pre {
    padding: 10px;
    background-color: #eee;
    white-space: pre-wrap;
}
.slateEditor :not(pre) > code {
    font-family: monospace;
    background-color: #eee;
    padding: 3px;
}
.slateEditor img {
    max-width: 100%;
    max-height: 20em;
    margin: 0 auto;
}
.slateEditor blockquote {
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
}
.slateEditor blockquote[dir='rtl'] {
    border-left: none;
    padding-left: 0;
    padding-right: 10px;
    border-right: 2px solid #ddd;
}
.slateEditor table {
    border-collapse: collapse;
}
.slateEditor td {
    padding: 10px;
    border: 2px solid #ddd;
}
.slateEditor input {
    box-sizing: border-box;
    font-size: 0.85em;
    width: 100%;
    padding: 0.5em;
    border: 2px solid #ddd;
    background: #fafafa;
}
.slateEditor input:focus {
    outline: 0;
    border-color: blue;
}
.slateEditor [data-slate-editor] > * + * {
    margin-top: 1em;
}
.headingFormat{
    border: 1px solid greenyellow;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    width: 30px;
    /* height: 50px; */
    padding-top: 2px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 4px;
    border-radius: 4px;
    border-color: rgb(240, 242, 247);
    line-height: 24px;
    position: absolute;
    top: 30px;
}
.headingFormatDefault {
    display: none;
}

.postsPreview {
    border: 1px solid #7a457d;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    padding: 10px 0;
    height: 808px;
    overflow-y: auto;
    flex: 0.4


}

.postEditTitle{
    width: 320px;
    padding: 10px;
    margin: 0 7.5px 10px 7.5px;
}

.postEditButton {
    padding: 10px;
    margin: 0 7.5px 10px 7.5px;
    width: 345px;
}