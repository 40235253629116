.contactUsWrapper{
     
    max-width: 1360px;
}

.contactTopInfo{
    background: #d4d4d4;
    padding: 30px;
    /* border: 1px solid greenyellow; */
}

.contactTopWrap {
    /* border: 1px solid hotpink; */
    padding: 50px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */

}

.svgContact{
    /* border: 1px solid green; */
    width: 40px;
    height: 40px;
}

.contactTopImage{

}

.contactTopInfo{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 20px 0;
}

.contactTopInfo a{
    text-decoration: none;
    color: #7a457d;
}

.contactFormWrap{
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    /* align-items: center;
    width: 75% */
}

.tact{
    width: 100%;
    height: 50%;
}

.contactForm{
    align-items: center;
    margin: 20px auto;
}

.contactForm > input, .contactForm textarea, .contactForm input[type='submit'] {
    width: 75%;
}

.contactFormWrap h2{
    text-transform: uppercase;
    color: black !important;
    font-size: 40px;
    margin-right: 20px;
    margin-bottom: 0;
}

.contactTopWrap{
    width: 300px;
    margin: 15px 0  ;
}

.contactTopWrap > * {
}

.contactTopIcon{
    padding: 0 20px 25px 0
}

.contactTopHead h2{
    margin-top: 0;
}



.contactTopMain {
    color: #7a457d;
    font-weight: 700;
}

.contactTopHead h2{
    font-weight: 800;
    font-size: 26px;
}