
.topHeaderWrapper {
    background: #7a457d;
    color: #bdb4bf;
    font-weight: 400;
}

.topHeader {
    /* border: 2px solid pink; */
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1360px;

}

.landingPageInfo{
    width: auto;
    min-height: 50vh;
    background: white;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 200;
    margin-top: -350px;
    margin-left: -250px;
    border: 3px solid #e0e0e2;
    overflow-y: auto;
    transition: 0.5s;
    display: none;

}

.landingClose{
    background: #9754a0;
    width: 50px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    color: #e0e0e2;
    padding: 2.5px 5px;
}

.landingPageContent{
    /* position: relative; */
    margin: 40px 40px;
    line-height: 25px;
    color: black ;
    text-align: center;
}

.landingPageContent img {
    /* width: 400px; */
    max-width: 100%;
    max-height: 100%;

}

@media screen and (max-width: 700px) {
    .landingPageInfo{
        width: 97.5vw;
        min-height: 50vh;
        top: 150px;
        left:.5vw;
        margin: 0 auto;
    }
}

.navInfo {
    /* border: 1px solid saddlebrown; */
    width: 65%;
    text-align: center;
}

.navWrapper{
    /* border: 1px solid #888896 */
    background: #e0e0e2;
    transition: all 0.5s ease;

}

.navFixed{
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 5 
}


.sidenav {
height: 100%;
  width: 0;
  position: fixed;
  z-index: 50;
  top: 0;
  right: 0;
  background-color: #e0e0e2;
  overflow-y: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav li {
    /* border: 1px solid olivedrab; */
    /* background: red; */
    padding: 5px;
    margin: 10px 5px;
    /* display: block; */
    width: fit-content;

}

.linksm {
    text-decoration: none;
    font-weight: 800;
    color: #7a457d;
    border: none;
    outline: none;
    border-bottom: 2px solid #bdb4bf;
    /* background: red; */

}

.linksm:focus{
    border: 0;
    outline: none;
    /* background: red; */
}


.closeButton {
position: absolute !important;
  top: 52px;
  right: 25px;
  margin-left: 50px;
}

.closeButton:hover {
    cursor: pointer;
}

.scrollToTop{
    display: none;
}

.scrollToTop:hover{
    bottom: 27px
}

.showScroll{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: grid !important;
    place-items: center;
    background-color: white;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
    position: fixed;
    right: 25px;
    bottom: 25px;    
    cursor: pointer;
    transition: 0.5 ease;
    color: #7a457d;
    z-index: 5000;
}

.mainHeader {
    /* border: 3px solid purple; */
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1360px;

}

.findUs {
    border-radius: 5px;
    height: 50px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #9754a0;
    transition: all .2s ease-in-out;

}

.findUs:hover{
    transform: scale(1.05);

}

.findUs a {
    text-decoration: none;
    color: #e0e0e2;
    text-align: center;
    background-color: #9754a0;

}

.logo{
    /* border: 2px solid black; */
    display: flex;
    justify-content: space-between;
}

.menu:hover {
    cursor: pointer;
}

.logoPicture {
    width: 280px;
    /* height: 90px; */
    /* border: 1px solid gold; */
    /* background-size: contain;
    background-repeat: no-repeat; */
    background-size: 100% 100%;
}

.logoPicture img {
    max-width: 100%;
    max-height: 100%;
    display: block;
}

.hamburger {
    display: none;
} 

.navLinks {
    /* border: 1px solid violet; */
    display: flex;
    align-items: center;
    list-style: none;
    flex-wrap: wrap;
    min-height: 35px;
}

.sidebarNav {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

}


.links {
    text-decoration: none;
    padding: 5px;
    margin: 0 5px;
    font-weight: 800;
    color: #7a457d;
    border-bottom: 5px solid transparent;


}

.links:hover {
    cursor: pointer;
    border-bottom: 5px solid #814888;
    border-radius: 5px;
}

.links:active {
    border-bottom: 2px solid #814888;
}


@media screen and (max-width: 1149px) {
    .mainHeader{
        flex-direction: column;
        justify-content: center;
    }

    .logo{
        align-self: start;
    }

    .navLinks{
        align-self: flex-end;
    }
}

@media screen and (max-width: 855px) {
    .topContact{
        display: none;
    }
    .navInfo{
        width: 80%;
    }
    .logo{
        width: 100%;
        align-items: center;
    }
    .hamburger{
        display: inline;
    }
    .navLinks{
        display: none;
    }
}

@media screen and (max-width: 450px) {
    .mainHeader {
        min-height: 50px;
        padding: 0 15px;
    }
    .navFixed{
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 5 
    }

    .topHeader{
        padding: 0 15px;

    }

    .logoPicture {
        width: 180px;
        height: 40px;
    }
       
}