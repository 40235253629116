.galleryWrapper {
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1360px;

}

.ReactGridGallery {
display: flex;
flex-wrap: wrap;
/* width: 98%; */
margin: 25px 0;

}

.ReactGridGallery_tile {
    float: none;
}

.pageNumber{
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.pageNumberItem{
    margin: 10px;
    cursor: pointer;
    text-decoration: underline;
}