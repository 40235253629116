.spinner-overlay-big {
    height: 60vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.spinner-container-big {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid #bdb4bf;
    border-radius: 50%;
    border-top-color: #7a457d;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}
@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
   }
}
@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
   }
}


.spinner-overlay-small {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.spinner-container-small {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 3px solid #bdb4bf;
    border-radius: 50%;
    border-top-color: #7a457d;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}
