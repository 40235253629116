.feedbackContainer {
    /* border: 2px solid pink; */

}

.feedbackWrapper {
    /* border: 1px solid teal; */
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1360px;
    background-color: rgba(0, 0, 0, 0.7);

}

@media screen and (max-width: 700px) {
    .feedbackWrapper {
        display: flex;
        flex-direction: column;
    }    
}

.feedbackOther {
    /* border: 1px solid saddlebrown; */
}

.formWrap {
    /* border: 1px solid #888896; */
    padding-left: 20px;

}

.formDiv {
    /* border: 3px solid purple; */

}

.feedbackForm {
    display: flex;
    flex-direction: column;
    /* width: 80%; */
    margin: 20px 20px;
}

.feedbackForm > * {
    margin: 10px;
    margin-left: 0;
}

.feedbackForm > input {
    padding: 15px 25px;
    font-size: 16px;
}

.feedbackForm > input[type='submit'] {
    background-color:#7a457d;
    border-radius: 5px;
    border: none;
    color: white;
}

.feedbackWrapper .feedbackForm textarea {
    height: 100px;
    padding: 15px 25px;
    font-size: 16px;
}

.feedbackForm > textarea {
    height: 100px;
    padding: 15px 25px;
    font-size: 16px;
}

.feedbackForm > input[type='submit']:hover {
    background-color:#814888;
    cursor: pointer;
}

.feedbackForm > input[type='submit']:focus {
    background-color:#e0e0e2;
}