.homeHeadWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.currentHeader {
    /* border: 1px solid gold; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.inputHeader {
    width: 300px;
    height: 100px;
    margin-bottom: 20px;
    font-size: 25px;
}

.newHeader{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.saveHeader{
    display: block;
    width: 300px;
    height: 40px;
    color: #e0e0e2;
    background-color: #7a457d;
    transition: all .3s ease-in-out;
    border: 0;
    border-radius: 5px;

}

.saveHeader:hover{
    cursor: pointer;
    background-color: #9754a0;
    transform: scale(1.01);
}

.saveHeader:active{
    border: 0;   
}

.saveHeader:focus{
    border: 0;
    outline: none;
}