.newsPreviewWrapper {
    /* border: 1px solid #656565; */
}

.newsPreview {
    padding: 0 20px;
    max-width: 1360px;
    margin: 20px auto;
}

.newsUpdate {
 /* border: 1px solid #125487 */
}

.newsUpdate > h3 {
    color: #7a457d;
    font-size: 24px;
}

.readAll {
    color: #814888;
    margin: 15px 0;
    /* border: 1px solid #623587; */
    text-decoration: none;

}

.postContainer {
    display: flex;
    flex-wrap: wrap;
    /* border: 1px solid #363258 */

}

.postWrapper {
    /* border: 1px solid khaki; */
    margin-bottom: 10px;
    margin-right: 20px;

}


.postPicture {
    /* border: 1px solid #043545; */
    height: 250px;
    max-width: 350px;
    margin: 10px 0;

}

.postDate {
    color: #959595;
    margin: 10px 0;
}

.postTitle {
    margin: 10px 0;
}

.titleStyle {
    text-decoration: none;
    color: #444444;
}