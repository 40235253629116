.careerWrap {
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1360px;
}

.careerForm {
    /* border: 1px solid green; */
}

.formTop{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 !important;
}

.careerForm * {
    margin: 20px;
    box-sizing: border-box;
    /* margin-left: 0; */
}

.careerForm input {
    padding: 15px 25px;
    font-size: 16px;
    min-width: 40%;
}


@media screen and (max-width: 700px) {
    .careerForm input {
        width: 100%;
    }
}

.careerForm input:last-child {
    width: 100%;
}


.careerForm input[type='submit'] {
    background-color:#7a457d;
    border-radius: 10px;
    border: none;
    color: white;
    margin-left: 0px;
    width: 100%
}

.careerForm textarea {
    height: 300px;
    width: 100%;
    padding: 15px 25px;
    font-size: 16px;
    margin: 0 !important;

}

.careerForm input[type='submit']:hover {
    background-color:#814888;
    cursor: pointer;
}

.careerForm input[type='submit']:focus {
    background-color:#e0e0e2;
}