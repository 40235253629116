.abitAboutUsWrapper {

}

.abitAboutUs {
    /* border: 2px solid springgreen; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    color: #e3dae5;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1360px;
    text-align: center;
}

.posth3{
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1360px;
    margin-bottom: 5px;

}

.newsposthome .newsPreview{
    margin-top: 0;
}

.newsposthome .newsContentMain{
    padding-top: 0;
}

@media screen and (max-width: 750px) {
    .abitAboutUs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .vision, .values, .mission{
        width: 66%;
    }
}

.vision, .values {
    /* border: 2px solid darkgoldenrod; */
    background-color: #9d68a0;
    height: 400px;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    

}

.mission {
    /* border: 2px solid coral; */
    background-color: #7a457d;
    height: 400px;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


}


.abitHeader {
    color: white;
    font-size: 20px;
}

.abitBody {
    font-size: 16px;
    padding: 20px;
}