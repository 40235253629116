/* @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); */

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

*{
  /* font-family: 'Montserrat', sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'EB Garamond', serif;
  /* font-family: 'Kufam', cursive; */
}