.homeGallWrap {
    /* border: 1px solid green */
    text-align: center;
}

.homeImagesWrap {
    border: 2px solid #7a457d;
    display: flex;
    overflow-x: scroll;
    width: calc(100% - 100px);
    margin: 20px auto;
}

.homeImage{
    /* height: 350px; */
    width: 500px;
    /* border: 1px solid firebrick */
}

.homeImageMini {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 300px;
    height: 296px;
    margin: 7.5px;
    border: 2px solid #7a457d;

}

.homeGallButt {
    display: flex;
    justify-content: center;
    transition: all .3s ease-in-out;

}

.hgButt {
    width: 300px;
    height: 40px;
    color: #e0e0e2;
    background-color: #7a457d;
    transition: all .3s ease-in-out;
    border: 0;
    border-radius: 5px;
    margin: 10px ;

}

.hgButt:hover{
    cursor: pointer;
    background-color: #9754a0;
    transform: scale(1.01);
}

.hgButt:active{
    border: 0;   
}

.hgButt:focus{
    border: 0;
    outline: none;
}

.gallAction {
    display: none;
    box-shadow: 0 0.5rem 3.125rem -0.75rem rgba(37,55,110,0.2);
    padding-top: 10px;
    padding-bottom: 50px;
    margin-top: 67px;
    transition: all .3s ease-in-out;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.close {
    position: absolute;
    top: 1px;
    right: 1px;
    background: #7a457d;
    border: none;
    border-radius: 2px;
    color: white;
    cursor: pointer;
}

.addImage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.addImage input {
    width: 280px;
    padding: 10px;
    margin-bottom: 10px;
}

.removeImage{

}

.afterDel{
    opacity: 0.1;
}

@media screen and (max-width: 840px) {
    .delTable {
        width: 300px;
        overflow-x: scroll; 
    }

    .delTableRow{
        width: 500px;
    }
    .tableImage{
        width: 100px !important        
    }

    .tableDesc{
        width: 130px !important
    }

}

.delTableRow{
    height: 100px;
    /* padding-top: 10px; */
    /* border: 1px solid gold; */
    display: flex;
    /* border-right: 1px solid green; */
}

.delTableRow:first-child{
    height: 50px;
    border-bottom: 1px solid #7a457d;

}

.delTableRow:first-child > * {
    display: grid;
    place-items: center;
}


.delTableRow > div {
    border-right: 1px solid #7a457d;
    padding: 15px;
}

.tableImage{
    width: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-left: 1px solid #7a457d;


}

.tableDesc{
    width: 140px;
}

.head{
    height: 50px !important;
    padding: 0 15px !important;
    /* border-bottom: 1px solid #7a457d; */
}

.tableCre{
    width: 100px;
}

.tableDel{
    width: 35px;
}

.delPicButt{
    margin-top: 10px;
    width: 100%;
}

.iconDel:hover{
    cursor: pointer;
}

.delTableRow * {
    /* margin: 0 10px; */
}


.delTableRow:nth-child(odd) {
    background-color: #f9f9f9;
    /* border: 1px solid olive; */
}

.delTableRow:nth-child(even) {
    background-color: #f3f3f3;
    /* border: 1px solid fuchsia; */
}

