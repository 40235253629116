.testimonyWrapper {

}
.testimony {
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1360px;

}

.ourteam{
    text-align: center;
    margin-bottom: 3.125rem;
    }
    
.teamwrap > * {
        line-height: 1.875rem;
        margin: 0.625rem auto;
    }



.teamrolewrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.teamdata{
    grid-column: 1 / 4;
    grid-row: 1 / 4;
    display: flex;
    flex-direction: column;
    align-content: center;
    box-shadow: 0 0.5rem 3.125rem -0.75rem rgba(37,55,110,0.2);
    border-radius: 0.75rem;
    background: #fff;
    min-height: 450px;
}
.teamrole{
    max-width: 20.125rem;
    margin: 1.25rem 1.25rem;
    display: grid;
    grid-template-columns: 8.75rem 3.75rem 1fr;
    grid-template-rows: 1.875rem 10.625rem 1fr;
}

.teamdatatext{
    background: white;
    width: 60%;
    align-self: flex-end;
    margin-right: 2.5rem;
    line-height: 2.1875rem;
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}

.teamdatatext > * {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;

}
